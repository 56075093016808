/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

:root {
  --primary-color: #c9262d;
  --primary-color-2: #aa1e25;
  --secondary-color: rgb(5, 85, 85);
  --secondary-color-2: rgb(1, 65, 65);
  --accent-color: #636363;
  --text-color: #363636;
  --text-color-2: #636363;
  --scaffold-background-color: #f0eeee;
  --active-link-bg-color: #faeae5;
  --app-toggle-btn-color: #c94242;
  --sidenav-bg-color: #1d1b1b;
  --sidenav-shadow-color: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --white: #ffffff;
  --teal: teal;
  --error: #af0000;
  --warning: orange;
  --form-control-placeholder-color: #636363;
  --chat-bg-left-color: #212121;
  --chat-bg-right-color: #000000;
  --auth-form-area-bg: linear-gradient(45deg, #ccc 45%, #fff 25%, #ccc 30%);
  --fg: #17181c;
  --shade1: #727274;
  --shade2: #cccdd1;
  --shade3: #f3f4f8;
  --shade4: #ffffff;
  --dur1: 1s;
  --dur2: 6s;
}

.slideBtn {
  font-weight: 800;
  letter-spacing: 1px;
  padding: 15px 34px 18px 34px;
  border-radius: 27px;
  -moz-border-radius: 27px;
  -webkit-border-radius: 27px;
  background: var(--primary-color);
  color: var(--white) !important;
  text-transform: uppercase !important;
  cursor: pointer;
}

.slideBtn:hover {
  background: var(--primary-color-2);
  color: var(--white) !important;
  text-transform: uppercase !important;
}

.form-button-holder {
  float: right;
  padding: 20px 0;
}

.gdlr-new {
  margin: -60px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
}

.gdlr-inner-new {
  box-shadow: 0 0 35px rgba(10, 10, 10, 0.12) !important;
  -moz-box-shadow: 0 0 35px rgba(10, 10, 10, 0.12) !important;
  -webkit-box-shadow: 0 0 35px rgba(10, 10, 10, 0.12) !important;
  background-color: #ffffff !important;
  border-radius: 3px 3px 3px 3px !important;
  -moz-border-radius: 3px 3px 3px 3px !important;
  -webkit-border-radius: 3px 3px 3px 3px !important;
  z-index: 999 !important;
}

.track-container {
  background-color: var(--shade3) !important;
}

.track-title {
  font-size: 30px !important;
  font-weight: 700 !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
  color: var(--primary-color-2) !important;
}

.quform-submit-inner2 .submit-button {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.8px;
  padding: 14px;
  width: 100%;
  display: block;
  -webkit-appearance: none;
  background-color: var(--primary-color-2);
  border: 0;
  margin-bottom: 30px;
  color: #fff;
  font-style: normal !important;
  cursor: pointer;
  font-style: normal;
  text-transform: uppercase !important;
}

.mr-10 {
  margin-right: 10px;
}

.isSmall {
  font-size: 12px;
}
.isSlim {
  font-weight: 300;
}
